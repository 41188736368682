import React from "react";

import { ShopifyFunctionsContextProvider } from "./StoreContext";

const CombinedProvider = ({ element }) => {
  return (
    <ShopifyFunctionsContextProvider>{element}</ShopifyFunctionsContextProvider>
  );
};

export default CombinedProvider;
