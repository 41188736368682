// require("dotenv").config();
import React, { useEffect, useReducer, useMemo, useContext } from "react";
import shopify from "shopify-buy";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { toast } from "react-toastify";
const ShopifyFunctionsContext = React.createContext(0);
ShopifyFunctionsContext.displayname = "ShopifyFunctionsContext";
const { Provider } = ShopifyFunctionsContext;

const persistedStateId = "shopifyCheckout";

function createShopifyClient() {
  return shopify.buildClient({
    domain: process.env.GATSBY_MYSHOPIFY_URL,
    storefrontAccessToken: process.env.GATSBY_SHOPIFY_STORE_ACCESS_TOKEN,
    // domain: process.env.GATSBY_MYSHOPIFY_URL,
    // storefrontAccessToken: process.env.GATSBY_SHOPIFY_STORE_ACCESS_TOKEN,
  });
}

const shopifyActions = {
  setLoading: "set_loading",
  setCheckout: "set_checkout",
};

function shopifyCheckoutReducer(_, action) {
  switch (action.type) {
    case shopifyActions.setLoading:
      return { loaded: false };
    case shopifyActions.setCheckout:
      const { lineItems = [], subtotalPrice = 0, webUrl = "" } = action.payload;
      return { lineItems, subtotalPrice, webUrl, loaded: true };
    default:
      throw new Error(`Action of type ${action.type} does not exist.`);
  }
}

export function ShopifyFunctionsContextProvider({ children }) {
  const client = useMemo(() => createShopifyClient(), [createShopifyClient]);

  const [shopifyCheckoutId, setShopifyCheckoutId] = useLocalStorage(
    persistedStateId,
    ""
  );
  const [checkout, dispatch] = useReducer(shopifyCheckoutReducer, {
    loaded: false,
    subtotalPrice: 0,
    lineItems: [],
    webUrl: "",
  });

  async function addItem({ variantId, quantity }) {
    const temporalCheckout = await client.checkout.addLineItems(
      shopifyCheckoutId,
      [{ variantId, quantity }]
    );
    dispatch({
      type: shopifyActions.setCheckout,
      payload: temporalCheckout,
    });
    toast("Your cart has been updated!");
  }

  async function removeItem(variantId) {
    const temporalCheckout = await client.checkout.removeLineItems(
      shopifyCheckoutId,
      [variantId]
    );
    // toastify;
    dispatch({
      type: shopifyActions.setCheckout,
      payload: temporalCheckout,
    });
    toast("Your cart has been updated!");
  }

  function resetCart() {
    setShopifyCheckoutId("");
    dispatch({
      type: shopifyActions.setLoading,
    });
  }

  async function updateItem({ id, quantity }) {
    const lineItemsToUpdate = [{ id, quantity: parseInt(quantity) }];

    try {
      const temporalCheckout = await client.checkout.updateLineItems(
        shopifyCheckoutId,
        lineItemsToUpdate
      );
      // toastify
      dispatch({
        type: shopifyActions.setCheckout,
        payload: temporalCheckout,
      });

      toast("Your cart has been updated!");
    } catch (err) {
      console.log(err);
    }
  }

  async function createNewCheckout() {
    const checkout = await client.checkout.create();
    setShopifyCheckoutId(checkout.id);
    return checkout;
  }

  async function checkCartExistence() {
    let temporalCheckout = null;
    if (shopifyCheckoutId === "") {
      temporalCheckout = createNewCheckout();
    } else {
      temporalCheckout = await client.checkout.fetch(shopifyCheckoutId);
      if (temporalCheckout === null || temporalCheckout.completedAt !== null) {
        temporalCheckout = createNewCheckout();
      }
    }

    dispatch({
      type: shopifyActions.setCheckout,
      payload: temporalCheckout,
    });
  }

  useEffect(() => {
    if (!client || !client.checkout) return;
    checkCartExistence();
  }, [client]);

  return (
    <Provider value={[addItem, removeItem, resetCart, updateItem, checkout]}>
      {children}
    </Provider>
  );
}
export function useShopifyFunctions() {
  const [addItem, removeItem, resetCart, updateItem, checkout] = useContext(
    ShopifyFunctionsContext
  );

  return { addItem, removeItem, resetCart, updateItem, checkout };
}
